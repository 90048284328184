<ng-select
  [clearable]="false"
  [formControl]="control"
  [items]="languages"
  [multiple]="false"
  [searchable]="false"
  [tabindex]="-1"
  bindValue="iso639_1"
  class="ng-select-sh ng-select-new"
>
  <ng-template let-clear="clear" let-item="item" ng-label-tmp>
    <ui-flag-icon [country]="item?.alpha2Code || item?.iso639_1"></ui-flag-icon>
    <div class="arrow">
      <svg-icon class="arrow-icon" src="/assets/icons/icons-arrow-down.svg"></svg-icon>
    </div>
  </ng-template>
  <ng-template let-index="index" let-item="item" ng-option-tmp>
    <div class="ng-option-label country">
      <ui-flag-icon [country]="item?.alpha2Code || item?.iso639_1"></ui-flag-icon>
      <span>{{ item?.nativeName || item?.name }}</span>
    </div>
    <svg-icon class="ng-option-checkmark" src="/assets/icons/icons-checkmark.svg"></svg-icon>
  </ng-template>
  <ng-template *ngIf="newLanguage" ng-footer-tmp>
    <div class="ng-option">
      <svg-icon class="ng-option-icon" src="/assets/icons/add-language.svg"></svg-icon>
      <div (click)="addLang.emit()" class="ng-option-label">
        <span>{{ 'SH.SELECT_LANGUAGE' | translate }}</span>
      </div>
    </div>
  </ng-template>
</ng-select>
