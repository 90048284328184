<form *ngIf="form" [formGroup]="form">
  @if (guest && guest.reservation && roomBox) {
    <lib-checkin-form-room-box [guest]="guest"></lib-checkin-form-room-box>
  }
  @if (guest && !surveyMode) {
    <ui-stay-timeline [checkIn]="guest.reservation?.from" [checkOut]="guest.reservation?.to"
                      [guestArea]="true"></ui-stay-timeline>
  }
  <div class="questions row">
    @for (control of questionControls; track control; let i = $index) {
      <ui-form
        [class.col-6]="shouldApplyCol6Class(control)"
        [class.is-checkin]="isCheckinOrCheckout(control) && guest"
        [formGroup]="control"
        class="consents"
      >
        <lib-checkin-form-question
          #question
          (loadMoreRooms)="onLoadMoreRooms($event)"
          [countries]="countries"
          [form]="$any(control)"
          [languages]="languages"
          [preview]="preview"
          [question]="$any(control?.value)"
          [roomLoading]="roomLoading"
          [rooms]="rooms"
          [translatePrefix]="translatePrefix"
        ></lib-checkin-form-question>
      </ui-form>
    }
  </div>
  <div class="files-section">
    @for (control of attachmentControls; track control; let i = $index) {
      <div class="file">
        <ui-form [hint]="filesHint(control?.value?.multiple)" class="files-sh-form">
          <label>
            <p [innerHTML]="control?.value?.title"></p>
            <em *ngIf="control?.value?.required">*</em>
          </label>
          <ui-file-uploader
            (fileAdded)="onFileAdd($event, control, i)"
            (fileRemove)="onFileRemove($event, control)"
            [multiple]="control?.value?.multiple"
            [pendingUpload]="pendingUploadFiles"
          ></ui-file-uploader>
          <ui-error-message
            [control]="control?.get('name')"
            [prefix]="translatePrefix"
          ></ui-error-message>
        </ui-form>
      </div>
    }
  </div>
  <div [class.disabled]="disableSignatureAndConsentsEdit" class="consents-section">
    @for (control of consentControls; track control; let i = $index) {
      <div [formGroup]="$any(control)" class="consents">
        <ui-form>
          <ui-checkbox
            [class.disabled]="preview"
            [formControlName]="'answer'"
            [isExpandable]="true"
            [isGA]="translatePrefix === 'GA'"
            [required]="control?.get('required')?.value"
            [value]="control?.get('answer')?.value"
            text="{{ control?.get('title')?.value }}"
          ></ui-checkbox>
          <ui-error-message [control]="control?.get('answer')" [prefix]="translatePrefix"></ui-error-message>
        </ui-form>
      </div>
    }
  </div>
  @if (showRoommates) {
    <div class="roommates-section">
      <h2>{{ 'GA.CHECK_IN-FORM.ROOMMATES' | translate }}</h2>
      <p>{{ 'GA.CHECK_IN-FORM.ROOMMATES.DESCRIPTION' | translate }}</p>
      <!-- @TODO move from ui feature-->
      <ui-add-roommate (roommateChange)="onRoommateChange($event)" [countries]="countries"></ui-add-roommate>
    </div>
  }
  <div
    (click)="onSignatureClick()"
    *ngIf="isDigitalSignatureEnabled || preview"
    [class.disabled]="preview"
    [class.is-not-preview]="!preview"
    class="signature"
  >
    <div *ngIf="!preview" class="signature-info">
      <ng-container *ngIf="showSignaturePadInfo">
        <svg-icon
          *ngIf="!preview"
          [class.opacity-0]="isSigned"
          class="signature-icon"
          src="/assets/icons/icons-phone-rotation.svg"
        ></svg-icon>
        <p [class.opacity-0]="isSigned" class="signature-info-title">{{ signatureTranslation }}</p>
      </ng-container>
      <div class="hr"></div>
    </div>
    <ui-form [class.disabled]="disableSignatureAndConsentsEdit">
      <signature-pad #signature (drawEnd)="onDrawEnd()" [options]="signaturePadOptions"></signature-pad>
      <ui-error-message [control]="form.get('digitalSignature')" [prefix]="translatePrefix"></ui-error-message>
    </ui-form>
    <a (click)="clearSignature()" *ngIf="showSignatureClearButton" class="clear-signature" href="javascript:">{{
        translateWithPrefix('CHECK_IN-FORM.SIGNATURE.CLEAR')
      }}</a>
  </div>
  @if (!preview) {
    <div class="buttons">
      <ui-button-rectangle
        (click)="onSaveClick()"
        *ngIf="!disableSignatureAndConsentsEdit"
        [disabled]="pending"
        [label]="submitText"
        [pending]="pending"
        size="l"
        type="primary"
      ></ui-button-rectangle>
      <ui-button-rectangle
        (click)="onReturn()"
        *ngIf="alreadySigned"
        [disabled]="pending"
        [label]="backText"
        class="return"
        size="l"
        type="white"
      ></ui-button-rectangle>
    </div>
  }
  @if (form.get('footer')?.value) {
    <p [innerHTML]="form.get('footer')?.value" class="footer"></p>
  }
</form>
