<button
  [class]="class"
  [disabled]="disabled"
  [ngClass]="[iconSize, sizeClass, typeClass, iconColor, hoverClass]"
  class="button"
  type="button"
>
  <svg-icon *ngIf="!loading" [src]="iconPath"></svg-icon>
  <ui-loader [color]="loaderColor" [showSpinner]="loading" size="s"></ui-loader>
</button>
