import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizedDatePipe } from 'angular-v2-pipes';

@Component({
  selector: 'ui-stay-timeline',
  standalone: true,
  imports: [CommonModule, TranslateModule, LocalizedDatePipe],
  templateUrl: './stay-timeline.component.html',
  styleUrls: ['./stay-timeline.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StayTimelineComponent {
  @Input() checkIn?: Date | string;
  @Input() checkInLabel = 'GA.CHECK_IN';
  @Input() checkInFrom?: string;
  @Input() checkOut?: Date | string;
  @Input() checkOutLabel = 'GA.CHECK_OUT';
  @Input() checkOutTo?: string;
  @Input() guestArea = false;

  get progress(): number {
    let checkInHour = 0;
    let checkOutHour = 0;

    if (!this.checkIn && !this.checkOut) {
      return 0;
    }

    if (this.checkInFrom) {
      checkInHour = parseInt(this.checkInFrom.split(':')[0]);
    }
    if (this.checkOutTo) {
      checkOutHour = parseInt(this.checkOutTo.split(':')[0]);
    }
    const startDate = new Date(this.checkIn ?? '').setHours(checkInHour);
    const endDate = new Date(this.checkOut ?? '').setHours(checkOutHour);
    const todayDate = new Date().getTime();
    const percentage = Math.round(((todayDate - startDate) / (endDate - startDate)) * 100);
    if (percentage < 0) {
      return 0;
    }
    return percentage > 100 ? 100 : percentage;
  }
}
