import { HttpClient } from '@angular/common/http';
import { SmartAssistantStatusResponse, SmartCoreAssistantStatus } from '@smart-assistant/types/v2';
import { map, Observable } from 'rxjs';

export class ConfigurationService {
  constructor(private readonly httpClient: HttpClient) {}

  getStatus(): Observable<SmartCoreAssistantStatus> {
    return this.httpClient
      .get<SmartAssistantStatusResponse>(`/smart-assistant-v2/client/configuration/status`)
      .pipe(map(res => res.status));
  }

  setStatus(status: SmartCoreAssistantStatus): Observable<SmartCoreAssistantStatus> {
    return this.httpClient
      .put<SmartAssistantStatusResponse>(`/smart-assistant-v2/client/configuration/status`, {
        status,
      })
      .pipe(map(res => res.status));
  }
}
