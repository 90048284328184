<div class="step">
  <div class="step-progress">
    <div [ngClass]="{ 'step-bullet-active': progress > 0 }" class="step-bullet"></div>
    <div class="step-line">
      <div [style.width.%]="progress" class="step-indicator"></div>
    </div>
    <div [ngClass]="{ 'step-bullet-active': progress === 100 }" class="step-bullet"></div>
  </div>
  <div class="step-labels">
    <div *ngIf="checkIn">
      <div class="step-label">
        <span>{{ checkInLabel | translate }}</span>
      </div>
      <div class="step-title">
        @if (guestArea) {
          <p>{{ checkIn | localizedDate : 'EEE, d MMM y' : 'UTC' }}</p>
        } @else {
          <p>{{ checkIn | date : 'EEE, d MMM y' }}</p>
        }
      </div>
    </div>
    <div *ngIf="checkOut" class="text-right">
      <div class="step-label">
        <span>{{ checkOutLabel | translate }}</span>
      </div>
      <div class="step-title">
        @if (guestArea) {
          <p>{{ checkOut | localizedDate : 'EEE, d MMM y' : 'UTC' }}</p>
        } @else {
          <p>{{ checkOut | date : 'EEE, d MMM y' }}</p>
        }
      </div>
    </div>
  </div>
</div>
