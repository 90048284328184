import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ICountry, ILanguage } from '@smarttypes/core';
import { QuestionTypeEnum } from '@smarttypes/registration-card';
import { ErrorMessageComponent, PhoneNumberComponent, SelectComponent, SelectCountryComponent } from '@ui/common/forms';
import { FormControlDirective } from 'angular-v2-directives';
import { SelectOption } from 'angular-v2-utils';
import { BsDatepickerModule, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';

import { FormQuestion } from '../checkin-form.component';

@Component({
  selector: 'lib-checkin-form-question',
  templateUrl: './checkin-form-question.component.html',
  styleUrls: ['./checkin-form-question.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    PhoneNumberComponent,
    ReactiveFormsModule,
    FormControlDirective,
    SelectComponent,
    TranslateModule,
    BsDatepickerModule,
    SelectCountryComponent,
    ErrorMessageComponent,
  ],
})
export class CheckinFormQuestionComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() question?: FormQuestion;
  @Input() languages?: ILanguage[];
  @Input() countries?: ICountry[];
  @Input() form?: FormGroup;
  @Input() preview = false;
  @Input() translatePrefix: 'GA' | 'SH' = 'GA';
  @Output() loadMoreRooms = new EventEmitter<{
    term: string;
    initial: boolean;
  }>();
  questionTypeEnum = QuestionTypeEnum;
  bsConfig: Partial<BsDaterangepickerConfig> = {
    adaptivePosition: true,
    initCurrentTime: true,
    containerClass: 'theme-sh',
    dateInputFormat: 'YYYY-MM-DD',
    showWeekNumbers: false,
    customTodayClass: 'today-picker-cell',
  };
  yesNo: { value: boolean; displayName: string }[] = [];
  private _rooms: SelectOption[] = [];
  @Input()
  set rooms(rooms: SelectOption[]) {
    this._rooms = rooms;
  }

  get rooms(): SelectOption[] {
    return this._rooms;
  }

  private _roomLoading = false;
  @Input()
  set roomLoading(roomLoading: boolean) {
    this._roomLoading = roomLoading;
  }

  get roomLoading(): boolean {
    return this._roomLoading;
  }

  roomInput$ = new Subject<string>();
  subscription = new Subscription();
  private roomSearchTerm = '';

  constructor(private translateService: TranslateService, private cdr: ChangeDetectorRef) {}

  get isRequired(): boolean {
    return this?.question?.required || false;
  }

  get isGuestArea(): boolean {
    return this.translatePrefix === 'GA';
  }

  ngOnInit(): void {
    this.yesNo = [
      {
        value: false,
        displayName: this.translateService.instant(`${this.translatePrefix}.NO`),
      },
      {
        value: true,
        displayName: this.translateService.instant(`${this.translatePrefix}.YES`),
      },
    ];
  }

  searchInput() {
    return this.roomInput$.pipe(
      filter(res => {
        this.roomSearchTerm = res;
        const count = res?.length ?? 0;
        return count >= 3 || count === 0;
      }),
      debounceTime(800),
      tap(() => this.onLoadMoreRooms(true)),
    );
  }

  onLoadMoreRooms(initial = false): void {
    this.loadMoreRooms.emit({ term: this.roomSearchTerm, initial: initial });
  }

  ngAfterViewInit() {
    if (this.question?.type === QuestionTypeEnum.apartmentNumber) {
      this.onLoadMoreRooms(true);

      this.subscription.add(
        this.searchInput().subscribe(() => {
          this.cdr.detectChanges();
        }),
      );
    }
  }

  addTag(name: string) {
    return { label: name, value: name };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
