import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'ui-list-item',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './list-item.component.html',
  styleUrl: './list-item.component.scss',
})
export class ListItemComponent {
  @Input({ required: true }) header!: string;
  @Input({ required: true }) description!: string;
  @Input() icon = 'icons-arrow-short-right-s';
}
