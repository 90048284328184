<button
  [disabled]="disabled"
  [ngClass]="[sizeClass, typeClass, hasIcon, iconSize, blockType, hasBottomLabel]"
  class="button"
  type="submit"
>
  <ui-loader [color]="loaderColor" [showSpinner]="pending" [size]="loaderSize"></ui-loader>
  <ui-avatar *ngIf="showAvatar" [key]="avatarKey" [object]="object" [size]="avatarSize" [type]="avatarType"></ui-avatar>
  <ng-container *ngIf="showIcon">
    <svg-icon [class.color]="color" [src]="iconPath" class="icon"></svg-icon>
  </ng-container>
  <span class="label">{{ label }}</span>
  <ng-content></ng-content>
  <span *ngIf="bottomLabel" class="bottom-label">{{ bottomLabel | maxChars: 40 }}</span>
  <svg-icon *ngIf="showArrow" class="arrow" src="/assets/icons/icons-arrow-down.svg"></svg-icon>
</button>
