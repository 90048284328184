import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { IStepOption } from 'ngx-ui-tour-core';
import { TourService, TourStepTemplateComponent } from 'ngx-ui-tour-ngx-bootstrap';

@Component({
  selector: 'sh-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonRectangleComponent, TourStepTemplateComponent],
})
export class OnboardingComponent {
  constructor(
    public tourService: TourService,
    private cd: ChangeDetectorRef,
  ) {}

  onButtonClick(step: IStepOption) {
    console.log('Next', this.tourService.hasNext(step));
    console.log(this.tourService);

    if (this.tourService.hasNext(step)) {
      this.tourService.next();
    } else {
      this.tourService.end();
    }
    this.cd.detectChanges();
  }
}
