import { HttpClient } from '@angular/common/http';

import { GuestService } from './_guest.service';

export class AssistantService {
  #guest: GuestService;

  get guest() {
    return this.#guest;
  }

  constructor(private readonly httpClient: HttpClient) {
    this.#guest = new GuestService(httpClient);
  }
}
