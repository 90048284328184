import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IGuest, IRoom } from '@smarttypes/hotel';
import { RoomBadgeComponent } from '@ui/features';

@Component({
  selector: 'lib-checkin-form-room-box',
  standalone: true,
  imports: [CommonModule, RoomBadgeComponent, TranslateModule],
  templateUrl: './checkin-form-room-box.component.html',
  styleUrls: ['./checkin-form-room-box.component.scss'],
})
export class CheckinFormRoomBoxComponent {
  @Input() guest!: IGuest;

  get roomImage(): string | undefined {
    return (this.room as IRoom)?.apartmentInfo?.additionalInfo?.['linkToPhotoImage'] as string;
  }

  get room(): IRoom | undefined {
    return this.guest?.reservation?.room ? (this.guest?.reservation?.room as IRoom) : undefined;
  }
}
