import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IMediaFile, MediaLibraryFileType } from '@smarttypes/core';
import { ButtonCircleComponent, ButtonRectangleComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MediaLibraryService } from 'angular-v2-services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxFilesizeModule } from 'ngx-filesize';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { ToastrService } from 'ngx-toastr';
import { finalize, first, map, tap } from 'rxjs';
import slugify from 'slugify';

import { MediaLibraryItemComponent } from '../media-library-item';
import { FileDropDownComponent } from './file-drop-down/file-drop-down.component';
import { FileTagEnum, FileTypeEnum } from './file-library.directive';

@Component({
  selector: 'ui-file-library',
  standalone: true,
  imports: [
    CommonModule,
    ButtonCircleComponent,
    TabsModule,
    TranslateModule,
    ButtonRectangleComponent,
    ImageCropperComponent,
    AngularSvgIconModule,
    NgxTippyModule,
    NgxFilesizeModule,
    FileDropDownComponent,
    MediaLibraryItemComponent,
  ],
  templateUrl: './file-library.component.html',
  styleUrls: ['./file-library.component.scss'],
})
export class FileLibraryComponent implements OnInit {
  @Output() action: EventEmitter<IMediaFile> = new EventEmitter();
  @Input() library = false;
  @Input() noUpload = false;
  @Input() folder?: string;
  @Input() tag!: FileTagEnum;
  @Input() type: FileTypeEnum = FileTypeEnum.any;
  @Input() maxFileSize = 5242880;
  @Input() acceptFiles: string[] = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml'];
  @ViewChild(ImageCropperComponent) imageCropper?: ImageCropperComponent;
  file!: File;
  croppedImage: string | null = null;
  showCropper = false;
  pending = false;
  selectedItem!: IMediaFile;
  mediaList!: IMediaFile[];
  folders: string[] = [];
  selectedFolder?: string;
  loading = true;

  constructor(
    public bsModalRef: BsModalRef,
    private mediaLibraryService: MediaLibraryService,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {}

  get noFilesAndFolders(): boolean {
    return (this.mediaList?.length ?? 0) === 0 && this.folders?.length === 0;
  }

  get noFilesInFolder(): boolean {
    return (this.mediaList?.length ?? 0) === 0 && !!this.selectedFolder;
  }

  isDisabledForSelect(item: IMediaFile) {
    if (this.type === FileTypeEnum.any) {
      return false;
    }
    return !item.contentType?.includes(this.type);
  }

  ngOnInit(): void {
    if (this.library) {
      this.loading = true;
      this.mediaLibraryService
        .folders()
        .pipe(
          first(),
          map(folders =>
            folders
              .filter(f => f !== '/')
              .map(f => f.replace(/\//g, '').replace(/-/g, ' '))
              .filter((x, i, a) => a.indexOf(x) === i)
              .sort((a, b) => a.localeCompare(b)),
          ),
          tap(folders => (this.folders = folders)),
        )
        .subscribe(() => {
          this.fetchFileList();
        });
    }
  }

  goBack() {
    this.selectedFolder = '';
    this.fetchFileList();
  }

  onFolderClick(folder: string) {
    if (this.selectedFolder !== folder) {
      this.selectedFolder = this.getDirectoryPath(folder);
      this.fetchFileList();
    }
  }

  shouldDisplayFolderTooltip(folder: string) {
    return folder.split('').length > 16;
  }

  onItemClick(item: IMediaFile) {
    if (this.isDisabledForSelect(item)) {
      return;
    }
    if (!this.noUpload) {
      this.selectedItem = item;
    }
  }

  onChooseClick(): void {
    console.log('Chosen file:', this.selectedItem);
    if (this.selectedItem) {
      this.action.emit(this.selectedItem);
      this.bsModalRef.hide();
    }
  }

  fileChangeEvent(event: File): void {
    this.mediaLibraryService
      .list(0, '', '', event.name)
      .pipe(
        map(res => {
          const files = res.filter(file => file.fileName === event.name);
          return files.length > 0;
        }),
      )
      .subscribe(isExist => {
        if (isExist) {
          this.toastr.info(`
            ${this.translateService.instant('SH.FRONTEND_LIB.FILE_LIBRARY.FILE_EXISTS_1')}
            ${event.name}
            ${this.translateService.instant('SH.FRONTEND_LIB.FILE_LIBRARY.FILE_EXISTS_2')}`);
          this.croppedImage = null;
          this.showCropper = false;
        } else {
          this.onFileChange(event);
        }
      });
  }

  onFileChange(event: File) {
    this.file = event;
    this.showCropper = event.type.includes('image/') && event.type !== 'image/svg+xml';
    if (!this.showCropper) {
      const reader = new FileReader();
      reader.readAsDataURL(event);
      reader.onload = () => {
        this.croppedImage = reader.result as string;
        this.save();
      };
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64 as string;
  }

  crop() {
    this.imageCropper?.crop();
  }

  cancelCrop() {
    if (this.croppedImage) {
      this.croppedImage = null;
    } else {
      this.showCropper = false;
    }
    this.bsModalRef.hide();
  }

  save() {
    if (this.pending) {
      return;
    }
    this.pending = true;
    this.mediaLibraryService
      .save(
        {
          directoryPath: this.folder ? `/${this.folder}/` : '/',
          fileName: this.file?.name,
          displayName: this.file?.name,
          contentType: this.file?.type,
          size: this.file?.size,
          type: MediaLibraryFileType.public,
        },
        this.croppedImage as string,
      )
      .pipe(finalize(() => (this.pending = false)))
      .subscribe(file => {
        this.action.emit(file);
        this.bsModalRef.hide();
      });
  }

  private fetchFileList() {
    this.loading = true;
    this.mediaList = [];
    this.mediaLibraryService
      .list(0, this.selectedFolder, '')
      .pipe(
        first(),
        finalize(() => (this.loading = false)),
      )
      .subscribe(res => {
        this.mediaList = res;
        this.cd.detectChanges();
      });
  }

  private getDirectoryPath(directory: string, withSlash = false) {
    const slug = slugify(directory, '-');
    return withSlash ? `/${slug}/` : slug;
  }
}
