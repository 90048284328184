import { Injectable } from '@angular/core';

import { LogLevel } from './log-level';

@Injectable({
  providedIn: 'any',
})
export class SmartLoggerService {
  private context?: string;

  static create(context: string): SmartLoggerService {
    const instance = new SmartLoggerService();
    instance.setContext(context);
    return instance;
  }

  setContext(context: string) {
    this.context = context;
  }

  static setLogLevel(level: LogLevel) {
    SmartLoggerService._logLevel = level;
  }

  static getLogLevel() {
    return SmartLoggerService._logLevel;
  }

  static log(...data: unknown[]) {
    if (SmartLoggerService.validateLogLevel(LogLevel.log)) {
      this._log(...data);
    }
  }
  static error(...data: unknown[]) {
    if (SmartLoggerService.validateLogLevel(LogLevel.error)) {
      this._error(...data);
    }
  }
  static warn(...data: unknown[]) {
    if (SmartLoggerService.validateLogLevel(LogLevel.warn)) {
      this._warn(...data);
    }
  }
  static debug(...data: unknown[]) {
    if (SmartLoggerService.validateLogLevel(LogLevel.debug)) {
      this._debug(...data);
    }
  }
  static table(tabularData?: unknown, properties?: string[]) {
    this._table(tabularData, properties);
  }

  log(...data: unknown[]) {
    this.context ? SmartLoggerService._log(this.context, ...data) : SmartLoggerService._log(...data);
  }
  error(...data: unknown[]) {
    this.context ? SmartLoggerService._error(this.context, ...data) : SmartLoggerService._error(...data);
  }
  warn(...data: unknown[]) {
    this.context ? SmartLoggerService._warn(this.context, ...data) : SmartLoggerService._warn(...data);
  }
  debug(...data: unknown[]) {
    this.context ? SmartLoggerService._debug(this.context, ...data) : SmartLoggerService._debug(...data);
  }
  table(tabularData?: unknown, properties?: string[]) {
    SmartLoggerService._table(tabularData, properties);
  }

  private static _logLevel: LogLevel = LogLevel.debug;

  private static _log = console.log.bind(console.log);
  private static _error = console.error.bind(console.error);
  private static _warn = console.warn.bind(console.warn);
  private static _debug = console.debug.bind(console.debug);
  private static _table = console.table.bind(console.table);

  private static validateLogLevel(level: LogLevel) {
    return level <= SmartLoggerService.getLogLevel();
  }
}
