import { HttpClient } from '@angular/common/http';
import { FormatQARequest } from '@smart-assistant/types/v2';

export class FormatQa {
  constructor(private readonly httpClient: HttpClient) {}

  format(payload: FormatQARequest) {
    return this.httpClient.post<FormatQARequest>(`/smart-assistant-v2/client/knowledge/helper/format-qa`, payload);
  }
}
