export enum CategoriesEnum {
  INFO = 'INFO',
  QA = 'QA',
  Apartment = 'Apartment',
}

export enum CreateCategoriesEnum {
  INFO = 'INFO',
  QA = 'QA',
}
