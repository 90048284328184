import { HttpClient } from '@angular/common/http';
import {
  BeautifyTextRequest,
  BeautifyTextResponse,
  GenerateTextRequest,
  GenerateTextResponse,
} from '@smart-assistant/types';
import { Observable } from 'rxjs';

import { SmartAssistantConfig } from '../../smart-assistant.config.interface';

export class TextService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly _config: SmartAssistantConfig,
  ) {}

  beautifyText(rq: BeautifyTextRequest): Observable<BeautifyTextResponse> {
    return this.httpClient.post<BeautifyTextResponse>('/smart-assistant/v2/text/beautify', rq);
  }

  generateText(rq: GenerateTextRequest): Observable<GenerateTextResponse> {
    return this.httpClient.post<GenerateTextResponse>('/smart-assistant/v2/text/generate', rq);
  }
}
