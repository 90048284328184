import { ResourceBase, ResourceType } from './common';

export enum SupportedFileSearchTypes {
  c = 'text/x-c',
  cpp = 'text/x-c++',
  cs = 'text/x-csharp',
  css = 'text/css',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  go = 'text/x-golang',
  html = 'text/html',
  java = 'text/x-java',
  js = 'text/javascript',
  json = 'application/json',
  md = 'text/markdown',
  pdf = 'application/pdf',
  php = 'text/x-php',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  py = 'text/x-python',
  rb = 'text/x-ruby',
  sh = 'application/x-sh',
  tex = 'text/x-tex',
  ts = 'application/typescript',
  txt = 'text/plain',
}

export enum SupportedCodeInterpreterTypes {
  c = 'text/x-c',
  cs = 'text/x-csharp',
  cpp = 'text/x-c++',
  csv = 'text/csv',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  html = 'text/html',
  java = 'text/x-java',
  json = 'application/json',
  md = 'text/markdown',
  pdf = 'application/pdf',
  php = 'text/x-php',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  py = 'text/x-python',
  py_script = 'text/x-script.python',
  rb = 'text/x-ruby',
  tex = 'text/x-tex',
  txt = 'text/plain',
  css = 'text/css',
  js = 'text/javascript',
  sh = 'application/x-sh',
  ts = 'application/typescript',
  csv_application = 'application/csv',
  jpeg = 'image/jpeg',
  gif = 'image/gif',
  pkl = 'application/octet-stream',
  png = 'image/png',
  tar = 'application/x-tar',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xml = 'application/xml',
  text_xml = 'text/xml',
  zip = 'application/zip',
}

export enum SupportedAudioFileTypes {
  flac = 'audio/flac',
  mp4 = 'audio/mp4',
  mpeg = 'audio/mpeg',
  ogg = 'audio/ogg',
  wav = 'audio/wav',
  webm = 'audio/webm',
}

export enum SupportedVideoFileTypes {
  mp4 = 'video/mp4',
  webm = 'video/webm',
}

export enum SupportedImageFileTypes {
  gif = 'image/gif',
  jpeg = 'image/jpeg',
  png = 'image/png',
  svg = 'image/svg+xml',
  webp = 'image/webp',
}

export interface FileResourceData {
  type: SupportedFileSearchTypes;
  fileName: string;
  displayName?: string;
  size?: number;
  description?: string;
  sourceUrl?: string;
}

export interface FileResource extends ResourceBase {
  type: ResourceType.File;
  data: FileResourceData;
}
