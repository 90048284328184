import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonCircleComponent } from '@ui/common/buttons';

@Component({
  selector: 'ui-remove-area',
  standalone: true,
  imports: [CommonModule, ButtonCircleComponent],
  templateUrl: './remove-area.component.html',
  styleUrl: './remove-area.component.scss',
})
export class RemoveAreaComponent {
  @Input({ required: true }) index!: number;
  @Output() action = new EventEmitter<number>();
}
