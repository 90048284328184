import { HttpClient } from '@angular/common/http';
import { SuggestAnswerRequest, SuggestAnswerResponse } from '@smart-assistant/types/v2';

export class GuestService {
  constructor(private readonly httpClient: HttpClient) {}

  suggestChatResponse(payload: SuggestAnswerRequest) {
    return this.httpClient.post<SuggestAnswerResponse>('/smart-assistant-v2/client/assistant/suggest/answer', payload);
  }
}
