<div (click)="checkbox.click()" [class.additional-margin]="isExpandable && lines > 1" class="width-100">
  <ui-check [checked]="checkboxValue" [class.small-gap]="smallGap" [disabled]="disabled"></ui-check>
  <div class="label">
    <input
      #checkbox
      [(ngModel)]="checkboxValue"
      [disabled]="disabled"
      [name]="id ?? ''"
      [required]="required"
      class="form-checkbox"
      type="checkbox"
    />
    <span
      #textElement
      [innerHTML]="text"
      [ngClass]="{ required: required, collapsed: !expanded }"
      class="form-checkbox-text"
    ></span>
  </div>
  <span (click)="onExpandClick()" *ngIf="isExpandable && lines > 1" class="toggle-link">
    {{
      isCollapsed
        ? (translationPrefix + 'CHECKBOX.EXPAND' | translate)
        : (translationPrefix + 'CHECKBOX.COLLAPSE' | translate)
    }}
  </span>
</div>
