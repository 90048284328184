<div>
  <signature-pad
    *ngIf="showSign && signaturePadOptions"
    #signature
    [options]="signaturePadOptions"
    (drawEnd)="onDrawEnd()"
  ></signature-pad>
  <div class="actions">
    <div class="row">
      <div class="col-6 d-flex align-items-center">
        <span class="link link-cancel" (click)="onCancel()">{{
            translateWithPrefix('CHECK_IN-FORM.SIGNATURE.CANCEL')
          }}</span>
        <span class="link link-clear" (click)="onClear()">{{
            translateWithPrefix('CHECK_IN-FORM.SIGNATURE.CLEAR')
          }}</span>
      </div>
      <div class="col-6 d-flex align-items-center justify-content-end" *ngIf="sign">
        <ui-button-rectangle
          type="primary"
          size="s"
          label="{{ translateWithPrefix('CHECK_IN-FORM.SIGNATURE.SIGN') }}"
          (click)="onSubmit()"
        ></ui-button-rectangle>
      </div>
    </div>
  </div>
</div>
