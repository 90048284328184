import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeHr from '@angular/common/locales/hr';
import localePl from '@angular/common/locales/pl';
import localeRu from '@angular/common/locales/ru';
import localeUk from '@angular/common/locales/uk';
import {
  APP_INITIALIZER,
  EnvironmentProviders,
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
  Provider,
  Type,
} from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular-ivy';
import { LanguageService, OwnerPathInterceptor } from '@smarthotel/angular-services';
import { MobileDeviceMessageComponent } from '@ui/features';
import { provideSmartAssistant } from '@ui/features/ngx-smart-assistant';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { take, tap } from 'rxjs';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ApplicationLanguageService } from './core/services/application-language.service';
import { PushNotificationsService } from './core/services/push-notifications.service';
import { SseEventsService } from './core/services/sse-events.service';
import { LocalizeService } from './services/localize.service';
import { StorageService } from './services/storage.service';
import { ViewsModule } from './views/views.module';

registerLocaleData(localeDe);
registerLocaleData(localeEn);
registerLocaleData(localePl);
registerLocaleData(localeEs);
registerLocaleData(localeUk);
registerLocaleData(localeRu);
registerLocaleData(localeHr);

function initializeTranslations(
  translateService: TranslateService,
  languageService: LanguageService,
  appLangService: ApplicationLanguageService,
): () => Promise<void> {
  return async (): Promise<void> => {
    // @TODO: MOVE AVAILABLE UI LANGUAGES TO ENVS !!!!
    const availableLanguages = ['en', 'pl', 'es'];
    // @TODO: move logic to i18n.service.ts
    // @TODO: use appLangService for this
    let language = 'en';
    const browserLanguage = translateService.getBrowserLang();
    const savedLanguage = StorageService.getItem('sh-admin-lang') as string;

    if (savedLanguage && availableLanguages.includes(savedLanguage)) {
      language = savedLanguage;
    } else if (browserLanguage && availableLanguages.includes(browserLanguage)) {
      language = browserLanguage;
    }

    return new Promise(resolve => {
      translateService.setDefaultLang(language);
      translateService
        .use(language)
        .pipe(
          take(1),
          tap(() => {
            appLangService.setLanguage(language, () => {
              languageService.selectedLang.next(language);
            });
          }),
        )
        .subscribe(() => resolve());
    });
  };
}

function createTranslateLoaderFromApi(http: HttpClient) {
  // TODO: move it to i18n.service.ts
  const params = [];

  if (environment?.i18n?.dev) {
    params.push('dev=true');
  }
  if (environment?.i18n?.force) {
    params.push('force=true');
  }
  if (environment?.i18n?.type) {
    params.push(`type=${environment.i18n.type}`);
  }

  const apiUrl = environment?.i18n?.url ? environment.i18n.url : `${environment.apiUrl}translate/app/sh/`;
  return new TranslateHttpLoader(http, `${apiUrl}`, `?${params.join('&')}`);
}

const imports: Array<Type<any> | ModuleWithProviders<{}> | any[]> = [
  CommonModule,
  BrowserModule,
  CoreModule,
  ViewsModule,
  AppRoutingModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: createTranslateLoaderFromApi,
      deps: [HttpClient],
    },
    isolate: false,
  }),
  AngularSvgIconModule.forRoot(),
  BrowserAnimationsModule,
  OverlayModule,
];

if (environment?.gtmId) {
  imports.push(
    GoogleTagManagerModule.forRoot({
      id: environment.gtmId,
    }),
  );
}

const providers: Array<Provider | EnvironmentProviders> = [Title, SseEventsService];

providers.push({
  provide: HTTP_INTERCEPTORS,
  useClass: OwnerPathInterceptor,
  multi: true,
});

providers.push({
  provide: 'NIGHTS_BRIDGE',
  useValue: environment.releaseName === 'nightsbridge-smart-chat',
});
providers.push(LocalizeService);

if (environment.sentryDns) {
  providers.push(
    ...[
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
    ],
  );
}

imports.push(
  ServiceWorkerModule.register('/firebase-messaging-sw.js', {
    enabled: environment.production,
    registrationStrategy: 'registerImmediately',
  }),
);

if (environment.firebaseCreds) {
  if (environment.registerPushNotifications) {
    providers.push(
      provideFirebaseApp(() =>
        initializeApp({
          ...environment.firebaseCreds,
        }),
      ),
    );
    providers.push(provideMessaging(() => getMessaging()));
    providers.push(PushNotificationsService);
  }
}

// SMART ASSISTANT
if (environment.assistantUrl) {
  providers.push(
    provideSmartAssistant({
      apiBase: environment.assistantUrl,
      withInterceptorsFromDi: true,
    }),
  );
}

// ---

providers.push({
  provide: APP_INITIALIZER,
  useFactory: initializeTranslations,
  deps: [TranslateService, LanguageService, ApplicationLanguageService],
  multi: true,
});

@NgModule({
  declarations: [AppComponent],
  imports: [imports, MobileDeviceMessageComponent],
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
