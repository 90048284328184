<ng-container *ngFor="let data of notifications">
  <ui-banner
    (buttonClick)="data?.buttonClickHandler ? data?.buttonClickHandler() : null"
    @showNotification
    [buttonText]="data?.buttonText ?? ''"
    [header]="data?.header"
    [hierarchy]="data?.hierarchy ?? 'high'"
    [html]="data?.html ? data?.description : ''"
    [iconLeft]="data?.icon"
    [noRadius]="true"
    [text]="!data?.html ? data?.description : ''"
    [type]="data?.type ?? 'warning'"
  ></ui-banner>
</ng-container>
