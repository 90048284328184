<div [class.search]="isSearch" class="page-content">
  <div *ngIf="!isSearch" class="page-header">
    <h1 [class.ai-gradient]="aiGradient">{{ name }}</h1>
    <ui-menu-badge *ngIf="beta"></ui-menu-badge>
  </div>
  <div class="page-actions">
    <ng-content></ng-content>
  </div>
</div>
<p *ngIf="description"><span [innerHTML]="description"></span></p>
