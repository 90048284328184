@if (files) {
  @for (file of files; track file.name; let i = $index) {
    <div class="file-uploader">
      <span class="file-name">{{ file.name | maxChars : 42 }}</span>
      <ui-button-square
        class="danger"
        icon="icons-trash"
        type="tertiary"
        size="s"
        [loading]="isLoading(file.name)"
        (click)="onRemove($event, i, isLoading(file.name))"
      ></ui-button-square>
    </div>
  }
}
@if (multiple || (!multiple && files.length === 0)) {
  <div class="file-uploader">
    <input (change)="onFileChoose($any($event.target).files)" [multiple]="multiple" hidden type="file" #fileInput/>
    <ui-button-rectangle
      label="{{ 'GA.FILE_UPLOAD.ADD_FILE' | translate }}"
      size="s"
      type="white"
      (click)="fileInput.click()"
    ></ui-button-rectangle>
  </div>
}
