<tour-step-template>
  <ng-template let-step="step">
    <div (click)="$event.stopPropagation()" class="popover-container">
      @if (step.artwork) {
        <img class="popover-artwork" [src]="step.artwork" [alt]="step.title">
      }
      @if (step.title) {
        <h3 class="popover-title popover-header">{{ step?.title }}</h3>
      }
      @if (step.content) {
        <div [innerHTML]="step.content" class="popover-content"></div>
      }
      <div class="popover-footer">
        <ui-button-rectangle
          (click)="onButtonClick(step)"
          [label]="step?.endBtnTitle"
          size="s"
          type="tertiary"
        ></ui-button-rectangle>
      </div>
    </div>
  </ng-template>
</tour-step-template>
