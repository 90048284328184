import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GrabberComponent, LinkedObjectsButtonComponent, TipComponent } from '@ui/common';
import { ButtonCircleComponent } from '@ui/common/buttons';
import { EditorConfigAllowedActionsEnum, ISection } from '@widgets/interfaces';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'wg-form-control',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    GrabberComponent,
    AngularSvgIconModule,
    ButtonCircleComponent,
    BsDropdownModule,
    TipComponent,
    LinkedObjectsButtonComponent,
  ],
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormControlComponent {
  @Input() section!: ISection;
  @Input() code!: string;
  @Input() icon?: string | undefined = 'icons-color-additional';
  @Input() title?: string;
  @Input() titlePrefix?: string;
  @Input() tags?: string[];
  @Input() parents?: string;
  @Input() counter?: number;
  @Input() movable = false;
  @Input() custom = false;
  @Input() divider = false;
  @Input() clickable = true;
  @Output() edit: EventEmitter<string> = new EventEmitter<string>();
  @Output() remove: EventEmitter<string> = new EventEmitter<string>();
  @Output() linkedObjects: EventEmitter<void> = new EventEmitter<void>();
  isMenuOpen = false;

  constructor(
    private readonly _cd: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {}

  get canRename(): boolean {
    return this.section?.editorConfig?.allowedActions?.includes(EditorConfigAllowedActionsEnum.renameSection) ?? false;
  }

  get canRemove(): boolean {
    return this.section?.editorConfig?.allowedActions?.includes(EditorConfigAllowedActionsEnum.removeSection) ?? false;
  }

  get hasActions() {
    return this.canRename || this.canRemove;
  }

  get sectionIcon(): string {
    if (this.icon) {
      return this.icon;
    }
    return 'icons-color-additional';
  }

  get tooltip(): string {
    if (!this.section?.tooltip) {
      return '';
    }
    return this.translateService.instant(`SH.VISITOR_PLACE.${this.section.name}.TOOLTIP`);
  }

  isOpenChange(state: boolean): void {
    this.isMenuOpen = state;
  }

  onOpenMenu(event: MouseEvent) {
    event.stopPropagation();
  }

  onEdit(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.edit.emit(this.code);
  }

  onRemove(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.remove.emit(this.code);
  }

  openLinkObjectedModal(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.linkedObjects.emit();
  }
}
