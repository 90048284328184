import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ui-menu-badge',
  templateUrl: './menu-badge.component.html',
  styleUrls: ['./menu-badge.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuBadgeComponent {
  @Input() @HostBinding('class') type: 'beta' | 'new-ai' | string = 'beta';
  @Input() text?: string;

  get label(): string {
    if (this.text) {
      return this.text;
    }
    switch (this.type) {
      case 'beta':
        return 'SH.BETA';
      case 'new-ai':
        return 'SH.NEW';
      default:
        return '';
    }
  }
}
