import { HttpClient } from '@angular/common/http';

import { FormatQa } from './helpers/format-qa';

export class HelpersService {
  #formatQa: FormatQa;

  get formatQa() {
    return this.#formatQa;
  }

  constructor(private readonly httpClient: HttpClient) {
    this.#formatQa = new FormatQa(httpClient);
  }
}
