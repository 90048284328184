import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';

export type LoaderColor = 'dark' | 'white' | 'blue' | 'danger' | 'dark-white' | 'whatsapp' | 'green';

@Component({
  selector: 'ui-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [CommonModule],
  encapsulation: ViewEncapsulation.None,
})
export class LoaderComponent {
  @Input() showSpinner = false;
  @Input() size: 's' | 'm' | 'l' | 'xl' = 'm';
  @Input() color: LoaderColor = 'blue';

  get sizeClass(): string {
    return `loader-${this.size}`;
  }

  get colorClass(): string {
    return `loader-${this.color}`;
  }
}
