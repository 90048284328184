import { HttpEvent, HttpEventType, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { SmartCoreAssistantStatus } from '@smart-assistant/types/v2';
import { last } from 'lodash';
import { catchError, Observable, tap } from 'rxjs';
import urlJoin from 'url-join';

import { SmartAssistantConfig } from '../smart-assistant.config.interface';
import { SmartAssistantService } from '../smart-assistant.service';

export function httpClientBaseUrlInterceptor(config: SmartAssistantConfig): HttpInterceptorFn {
  return (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    if (req.url.includes('/smart-assistant-v2/')) {
      if (!req.url.includes('extra/status') && !req.url.includes('configuration/status')) {
        SmartAssistantService.setServiceStatus(SmartCoreAssistantStatus.PROCESSING);
      }

      req = req.clone({
        url: urlJoin(config.apiBase, last(req.url.split('smart-assistant-v2/')) as string),
      });

      return next(req).pipe(
        tap(event => {
          if (event.type === HttpEventType.Response) {
            if (!req.url.includes('extra/status') && !req.url.includes('configuration/status')) {
              SmartAssistantService.setServiceStatus(SmartCoreAssistantStatus.ACTIVE);
            }
          }
        }),
        catchError(err => {
          if (!req.url.includes('extra/status') && !req.url.includes('configuration/status')) {
            SmartAssistantService.setServiceStatus(SmartCoreAssistantStatus.ACTIVE);
          }

          return next(err);
        }),
      );
    }
    return next(req);
  };
}
