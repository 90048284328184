import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '@ui/common';
import { ButtonRectangleComponent, ButtonSquareComponent } from '@ui/common/buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MaxCharsPipe } from 'angular-v2-pipes';

@Component({
  selector: 'ui-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AngularSvgIconModule,
    LoaderComponent,
    TranslateModule,
    MaxCharsPipe,
    ButtonRectangleComponent,
    ButtonSquareComponent,
  ],
})
export class FileUploaderComponent {
  @Input() multiple = false;
  @Input() pendingUpload: File[] = [];
  @Output() fileAdded: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() fileRemove: EventEmitter<File> = new EventEmitter<File>();
  files: File[] = [];
  loading = false;

  isLoading(fileName: string): boolean {
    return this.pendingUpload.some(f => f.name === fileName);
  }

  onFileChoose(files: File[]) {
    this.files.push(...files);
    this.fileAdded.emit(files);
    this.loading = true;
  }

  onRemove(event: MouseEvent | TouchEvent, index: number, loading: boolean) {
    event.preventDefault();
    event.stopPropagation();
    if (loading) {
      return;
    }
    this.fileRemove.emit(this.files[index]);
    this.files.splice(index, 1);
  }
}
