<ng-container *ngFor="let item of items">
  <sh-menu-item
    [allowForTrialUsers]="item?.allowForTrialUsers"
    [badge]="item?.badge"
    [canDisplay]="item.canDisplay"
    [counter]="item.counter | async"
    [disabled]="item?.disabled"
    [icon]="item.icon"
    [label]="item.label"
    [path]="item.path"
    [totalCounter]="item.totalCounter | async"
    [tourAnchor]="item?.tourAnchor ?? ''"
    [unlockRequired]="item?.unlockRequired"
    [warning]="item?.warning | async"
  ></sh-menu-item>
</ng-container>
